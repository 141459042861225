import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
import TrackLayout from 'components/layout/Track'
import { ThemeProvider, withTheme } from 'styled-components/macro'
import colors from 'styles/colors'
import { StaticLoader } from 'components/misc/Loader'
import GlobalStyle from 'styles/Global'
import { isTrackURL, redirectToTrackUrl } from 'util/track'
import { api } from 'roadie-ui'
import FormPage from 'rui/track/FormPage'
import TrackingPage from 'rui/track/TrackingPage'
import favicon from 'images/default-track-favicon.png'
import themeUtil, { convertTrackThemeToRoadieUITheme } from "../util/theme";

const fetchedThemes = {}

function getTheme(themeName) {
  return new Promise((res, rej) => {
    if (fetchedThemes[themeName]) {
      res(fetchedThemes[themeName])
    } else {
      const bucket = api.external().create({
        baseURL: themeUtil.url(themeName),
      })
      bucket.get(`/theme.json`).then(resp => {
        const theme = convertTrackThemeToRoadieUITheme(resp.data)
        // TEMP remove this line when track is fully RoadieUI:
        const themeColors = { ...colors, ...(resp.data.colors || {}) }
        // TEMP remove this line when track is fully RoadieUI:
        theme.colors = themeColors
        res(fetchedThemes[themeName] = theme)
      }).catch(rej)
    }
  }).then(theme => ({ default: props => (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )}))
}

const Head = props => (
  <Helmet>
    <title>{props.theme.pageTitle}</title>
    { props.theme.favicon ?
      <link rel="shortcut icon" href={themeUtil.image(props.themeName, props.theme.favicon)}></link> :
      <link rel="shortcut icon" href={favicon}></link>
    }
  </Helmet>
)

const ThemedHead = withTheme(Head)

const themed = theme => lazy(() => getTheme(theme).catch(() => {
  if (!isTrackURL()) {
    redirectToTrackUrl()
    return { default: () => <span /> }
  } else {
    return { default: () => (
      <Switch>
        <Redirect to="/id/:tracking" from="/:theme/id/:tracking" />
        <Redirect to="/" />
      </Switch>
    ) }
  }
}))

const hasTheme = Compoment => props => {
  const { theme } = props.match.params
  const Themed = themed(theme)
  return (
    <Suspense fallback={<StaticLoader />}>
      <Themed>
        <>
          <GlobalStyle />
          <ThemedHead themeName={theme} />
          <TrackLayout themeName={theme} {...props}>
            <Compoment {...props} themeName={theme} />
          </TrackLayout>
        </>
      </Themed>
    </Suspense>
  )
}

const noTheme = Compoment => props => {
  if (!isTrackURL()) {
    const theme = window.location.host
    const Themed = themed(theme)
    return (
      <Suspense fallback={<StaticLoader />}>
        <Themed>
          <>
            <GlobalStyle />
            <ThemedHead themeName={theme} />
            <TrackLayout themeName={theme} {...props}>
              <Compoment {...props} themeName={theme} />
            </TrackLayout>
          </>
        </Themed>
      </Suspense>
    )
  }
  
  return (
    <>
      <GlobalStyle />
      <ThemedHead />
      <TrackLayout {...props}>
        <Compoment {...props} />
      </TrackLayout>
    </>
  )
}

const TrackRouter = props => (
  <Router basename={process.env.REACT_APP_BASENAME}>
    <Switch>
      {/* Redirect from old URL */}
      <Route path="/:theme/track" render={props => {
        const query = new URLSearchParams(props.location.search)
        const tracking = query.get('tracking_number')
        return <Redirect to={`/${props.match.params.theme}/id/${tracking}`} />
      }} />
      <Route path="/track/:code" render={props => {
        return <Redirect to={`/id/${props.match.params.code}`} />
      }} />
      <Route path="/track" render={props => {
        const query = new URLSearchParams(props.location.search)
        const tracking = query.get('tracking_number')
        return <Redirect to={`/id/${tracking}`} />
      }} />
      
      <Route path="/id/:tracking" component={noTheme(TrackingPage)} />
      <Route path="/" exact component={noTheme(FormPage)} />
      <Route path="/:theme/id/:tracking" component={hasTheme(TrackingPage)} />
      <Route path="/:theme" exact component={hasTheme(FormPage)} />
      <Redirect to="/:theme" from="/:theme/*" />
      <Redirect to="/" />
    </Switch>
  </Router>
)

export default TrackRouter