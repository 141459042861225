import React from 'react'
import { Container } from 'roadie-ui'

const Thin = props => (
  <Container.Grid $width="100%" $justifyContent="center">
    <Container.Base $width="100%" $maxWidth="48rem" $maxWidthMedia={{mobile: 'none'}}>
      {props.children}
    </Container.Base>
  </Container.Grid>
)

export default Thin