window.fbAsyncInit = function() {
  window.FB.init({
    appId            : process.env.REACT_APP_FACEBOOK_APP_ID,
    autoLogAppEvents : true,
    xfbml            : true,
    version          : 'v3.2'
  })
}

if (!document.getElementById('facebook-jssdk')) {
  const fjs = document.querySelector("script")
  const js = document.createElement('script')
  js.id = 'facebook-jssdk'
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}