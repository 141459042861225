import React, { FC, useEffect, useState } from 'react'
import { Container, Spacer, Text, Loader, Image } from 'roadie-ui'
import { TrackingApiData } from "./types"
import { capitalize } from 'util/format'
import { fetchTrackingDriverAvatar } from 'data/track'
import { Time } from 'roadie-ui'

type Props = {
  tracking: TrackingApiData
  code: string
  concludedAt?: Time
}

const formatTracking = (code:string) => {
  const [,a,b,c,d] = code.toUpperCase().match(/(.{4})(.{4})(.{4})(.{4})/)!
  return `${a} - ${b} - ${c} - ${d}`
}

function is24HoursSinceConcluded(time?:Time) {
  if (!time) {
    return false
  }
  const target = time.addTime("24:00")
  return new Date() > target.date
}

const TrackingDeliveryInfo:FC<Props> = props => {
  const { tracking, code, concludedAt } = props
  const [avatarUrl, setAvatarUrl] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchTrackingDriverAvatar(code)
      .then(resp => {
        setAvatarUrl(resp.verified_avatar_url ?? resp.avatar_url ?? '')
      })
      .finally(() => setLoading(false))
  }, [code])

  return (
    <Container.Panel className="tracking-delivery-info">
      {(is24HoursSinceConcluded(concludedAt) || !tracking.roadie) ? null : (
        <Container.Grid
          $gridTemplateColumns="6.4rem 1fr"
          $gridTemplateRows="6.4rem"
          $gridGap="1rem"
          $alignItems="center"
        >
          <Container.Grid
            className="info-avatar"
            $backgroundColor="grey.lighter"
            $borderRadius="50%"
            $alignItems="center"
            $justifyContent="center"
            $overflow="hidden"
          >
            {loading ? <Loader /> : avatarUrl ? (
              <Image
                src={avatarUrl}
                $width="6.4rem"
                $height="6.4rem"
                $objectFit="cover"
                $borderRadius="50%"
              />
            ) : null}
          </Container.Grid>
          <Container.Base>
            <Text.Body1 className="info-name" $color="black">{tracking.roadie.name}</Text.Body1>
            <Text.Caption className="info-vehicle">{capitalize(tracking.roadie.vehicle.color)} {tracking.roadie.vehicle.make} {tracking.roadie.vehicle.model}</Text.Caption>
          </Container.Base>
        </Container.Grid>
      )}
      <Spacer $height={is24HoursSinceConcluded(concludedAt) ? '0' : '1.6rem'} />
      <Text.Overline>Signature Required</Text.Overline>
      <Text.Body1 className="info-signature-required" $color="black">{tracking.delivery_confirmation_required ? 'Yes' : 'No'}</Text.Body1>
      <Spacer $height="1.6rem" />
      <Text.Overline>Tracking Number</Text.Overline>
      <Text.Body1 className="info-tracking-number" $color="black">{formatTracking(code)}</Text.Body1>
    </Container.Panel>
  )
}

export default TrackingDeliveryInfo