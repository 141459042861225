type NumberFormatOptions = {
  roundBy?: number
  maxLength?: number
}

interface CurrencyFormatOptions extends NumberFormatOptions {
  includeDecimal?: boolean
}

export function formatPhone(phone:string) {
  let digits = String(phone).replace(/[^\d*]/g, '').replace(/^1/, '')
  return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)}-${digits.substr(6, 4)}`
}

export function formatCurrency(num:number | string, options:CurrencyFormatOptions = {}) {
  const { roundBy:round, maxLength=Number.MAX_SAFE_INTEGER-1, includeDecimal=true } = options
  const rounded = roundBy(num, round || (includeDecimal ? 0 : 1))

  const [beforeDecimal, afterDecimal=0] = rounded.toFixed(2).split('.')
  
  if (includeDecimal && (withCommas(beforeDecimal).length + 4) <= maxLength) {
    return `$${withCommas(beforeDecimal)}.${afterDecimal}`
  } else {
    return `$${formatNum(rounded, { roundBy: 1, maxLength: maxLength - 1 })}`
  }
}

export function roundBy(num:string | number, by?:number) {
  if (!by) {
    return Number(num)
  }
  return Math.round(Number(num) / by) * by
}

export function withCommas(n:string | number):string {
  if (String(n).indexOf('.') >= 0) {
    const [a,b] = String(n).split('.',2)
    return withCommas(a) + '.' + b
  }
  return String(n).replace(/(\d)(?=(\d{3})+($|\.\d*$))/g, '$1,')
}

export function formatNum(num:string | number, options:NumberFormatOptions = {}) {
  const { roundBy:round, maxLength=Number.MAX_SAFE_INTEGER } = options
  const rounded = roundBy(num, round)

  const billion = 1_000_000_000
  const million = 1_000_000
  const thousand = 1_000

  if (withCommas(rounded).length <= maxLength) {
    return withCommas(rounded)
  }

  const condense = (by:number, postfix:string) => {
    const [beforeDecimal, afterDecimal='0'] = String(rounded / by).split('.')
    const formatted = withCommas(beforeDecimal)
    const decimalPlace = Math.min(2, String(afterDecimal).length, Math.max(0,maxLength - (formatted.length + 1 + postfix.length)))
    const decimal = afterDecimal.substr(0,decimalPlace)
    const decimalFormatted = Number(decimal) ? `.${decimal}` : ''
    return `${formatted}${decimalFormatted}${postfix}`
  }

  if (rounded >= billion) {
    return condense(billion, 'b')
  } else if (rounded >= million) {
    return condense(million, 'm')
  } else if (rounded >= thousand) {
    return condense(thousand, 'k')
  } else {
    return condense(1, '')
  }
}

/** Capitalizes the first letter without modifying the rest of the string */
export function capitalize(str:string) {
  str = str || ''
  if (!str.length) {
    return str
  }
  return str[0].toUpperCase() + str.slice(1)
}

/** Capitalizes the first letter and down cases the rest */
export function capCase(str:string) {
  str = str || ''
  return capitalize(str.toLowerCase())
}

/** Converts a string like "foo_bar" to "Foo Bar" */
export function humanize(str:string) {
  return str.split('_').map(capitalize).join(' ')
}