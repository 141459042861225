import React from 'react'
import styled, { withTheme, css } from 'styled-components/macro'
import { isTrackURL } from 'util/track'
import MainLayout from 'rui/widgets/MainLayout'
import { Button, Container, hooks } from 'roadie-ui'
import themeUtil from "util/theme";

const ThemeLogo = styled.img`
  height: ${props => props.height || '4.6rem'};
  ${props => props.width && css`
    width: ${props.width};
  `}
`

function TrackAnotherLink(props) {
  const { theme, themeName, match: { path } } = props
  const isMobile = hooks.useIsMediaSize('mobile')
  
  if (path === '/' || path === '/:theme' || isMobile) {
    return null
  }
  
  return (
    <Button.Link.Tertiary
      to={isTrackURL() && themeName ? `/${themeName}` : '/'}
      $padding="2.3rem 3.5rem"
      $color={theme.headerfg || 'white'}
      $colorHover={`${theme.headerfg || 'white'}@0.9`}
    >Track Another Delivery</Button.Link.Tertiary>
  )
}

const TrackHeader = props => {
  const { theme } = props
  const isMobile = hooks.useIsMediaSize('mobile')
  const logo = isMobile && theme.logoMobile ? theme.logoMobile : theme.logo

  return (
    <Container.Grid
    $backgroundColor="lookup:headerbg"
    $alignItems="center"
    $justifyContent="stretch"
    $justifyItems="center"
    >
    <Container.Grid
      $height="6.4rem"
      $width="100%"
      $maxWidth="102.5rem"
      $justifyContent="space-between"
      $gridTemplateColumns="auto auto"
      $alignItems="center"
      $padding="0 1.5rem"
      $paddingMedia={{mobile: '0'}}
    >
      <Button.A.Tertiary href={logo.link} $padding={isMobile ? '0 0 0 1.5rem' : '0'}>
        <Container.Grid $alignItems="center">
          <ThemeLogo height={logo.height} width={logo.width} src={themeUtil.image(props.themeName, logo.src)} />
        </Container.Grid>
      </Button.A.Tertiary>
      <TrackAnotherLink {...props} />
    </Container.Grid>
    <Container.Base $height="1px" $width="100%" $backgroundColor="lookup:headerborder" />
  </Container.Grid>
  )
}

const TrackLayout = props => (
  <MainLayout
    fullscreen={true}
    header={<TrackHeader {...props} />}
    footer={false}
  >
    {props.children}
  </MainLayout>
)

export default withTheme(TrackLayout)