import 'declared_modules'
import 'util/console'
import 'fbSDK'
import 'GTM'
import 'util/timeSetup'
import React from 'react'
import ReactDOM from 'react-dom'
import { renderToStaticMarkup } from "react-dom/server"
import * as roadieWorker from './roadieWorker'
import { HelmetProvider } from 'react-helmet-async'
import RoadieThemeProvider from 'RoadieThemeProvider'
import { api, setupEnvironment, initSession, MapSystem } from 'roadie-ui'
import TrackApp from './TrackApp'

MapSystem.setup({ renderToStaticMarkup })
setupEnvironment({
  PRODUCTION: process.env.NODE_ENV === 'production',
  REACT_APP_ROADIE_API_URL: process.env.REACT_APP_ROADIE_API_URL,
  REACT_APP_MESSAGES_URL: process.env.REACT_APP_MESSAGES_URL
})
api.setApiBaseUrl(new URL(process.env.REACT_APP_ROADIE_API_URL!).host)
const bogusSession = {
  profile_id: 0,
  guid: '',
  user_identity_guid: '',
  current_profile_guid: '',
  access_token: '',
  refresh_token: '',
  access_token_valid_until: '',
  refresh_token_valid_until: '',
}
initSession({
  getSessionInfo: () => bogusSession,
  cacheSessionInfo: () => {},
  patchSessionInfo: (session) => bogusSession,
  destroySessionInfo: () => {}
})

if (process.env.REACT_APP_CAN_USE_CUSTOM_API) {
  const query = new URLSearchParams(window.location.search)
  const apiBase = query.get('api') || sessionStorage.getItem("api-base")
  if (apiBase) {
    sessionStorage.setItem("api-base", apiBase)
    api.setApiBaseUrl(apiBase)
  }
} else {
  sessionStorage.removeItem("api-base")
}

ReactDOM.render(
    <>
    {/* @ts-expect-error */}
    <HelmetProvider>
      <RoadieThemeProvider>
        <TrackApp />
      </RoadieThemeProvider>
    </HelmetProvider>
  </>,
  document.getElementById('root'),
)

roadieWorker.register()