import React, { FC, ReactNode, useCallback } from 'react'
import styled from 'styled-components/macro'
import { api, Box, Button, Text, Span, Container, Spacer, Messages } from 'roadie-ui'

const BGWrapper = styled.div`
  > * {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`

const CustomApi = () => {
  const apiURL = sessionStorage.getItem("api-base")
  const resetApiBaseUrl = useCallback(() => {
    api.resetApiBaseUrl()
  }, [])
  if (apiURL) {
    return (
      <Box.Model
        $padding=".5rem 0 0"
        $textAlign="center"
        $backgroundColor="alert"
        $borderBottomWidth="3px"
        $borderBottomStyle="dashed"
        $borderBottomColor="primary.darker"
        $color="black"
        $height="5rem"
      >
        <Container.Grid
          $gridTemplateColumns="auto auto"
          $alignItems="center"
          $justifyItems="center"
        >
          <span><b>CUSTOM API:</b> {apiURL} </span>
          <Button.Primary $hue="alert" onClick={resetApiBaseUrl}>Unset</Button.Primary>
        </Container.Grid>
      </Box.Model>
    )
  }
  return null
}

type LayoutProps = {
  children: ReactNode
  sidebar?: ReactNode
  background?: ReactNode 
  needs?: string
  header?: false | ReactNode
  footer?: boolean
  fullscreen?: boolean
}

const MainLayout:FC<LayoutProps> = props => {
  const { children, header=true, footer=true, background=<Container.Base $backgroundColor="white" />, fullscreen=false } = props
  let maxWidth = '102.5rem'
  let pagePadding = '5rem'

  if (fullscreen) {
    maxWidth = '100%'
    pagePadding = '0'
  }
  return (
    <div>
        <BGWrapper>
          {background}
        </BGWrapper>
        <Container.Grid
          $minHeight="100vh"
          $gridTemplateRows="auto 1fr auto"
        >
          <div>
            <CustomApi />
            {header}
          </div>
        <div>
            <Container.Grid
              $padding={`${pagePadding} ${pagePadding} 0`}
              $paddingMedia={{mobile: '0'}}
              $width="100%"
              $maxWidth={maxWidth}
              $minHeight="100%"
              $gridTemplateColumns="100%"
              $justifyItems="center"
            >
              {children}
            </Container.Grid>
          </div>
          <div>
            {footer ? (
              <div>
                <Spacer $height="4rem" />
                <Container.Position $position="relative">
                  <Container.Base>
                    <Container.Base $textAlign="center">
                      <Span.Body2><a href="https://support.roadie.com" target="_blank" rel="noopener nofollower noreferrer">Help</a></Span.Body2>
                      <Span.Body2 $userSelect="none" $color="grey.light">{' // '}</Span.Body2>
                      <Span.Body2><a href="https://www.roadie.com/privacy" target="_blank" rel="noopener nofollower noreferrer">Privacy</a></Span.Body2>
                      <Span.Body2 $userSelect="none" $color="grey.light">{' // '}</Span.Body2>
                      <Span.Body2><a href="https://www.roadie.com/terms" target="_blank" rel="noopener nofollower noreferrer">Terms</a></Span.Body2>
                    </Container.Base>
                    <Spacer $height=".4rem" />
                    <Text.Body2 $textAlign="center">©{new Date().getFullYear()} Roadie, Inc. All rights reserved</Text.Body2>
                  </Container.Base>
                </Container.Position>
                <Spacer $height="4rem" />
              </div>
            ) : null}
          </div>
        </Container.Grid>
      { <Messages app="track" /> }
    </div>
  )
}

export default MainLayout