import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import colors from 'styles/colors'

const fadeInOpacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`

const LoaderBackground = styled.div`
  background: ${props => props.theme.colors[props.background] || props.background || colors.greyDarker};
  width: ${props => `${(props.size || 25)/10}rem`};
  height: ${props => `${(props.size || 25)/10}rem`};
  border-radius: 50%;
  opacity: .75;
  animation-name: ${fadeInOpacity};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .3s;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
`

const LoaderForeground = styled.div`
  display: inline-block;
  width: 70%;
  height: 70%;
  border: 4px solid ${props => props.theme.colors[props.foreground] || props.foreground || props.theme.colors.white};
  animation: ${spin} .5s linear infinite, ${fadeInOpacity};
  border-width: 0px;
  border-radius: 50%;
  border-top: 3px solid ${props => props.theme.colors[props.foreground] || props.foreground || props.theme.colors.white};
  border-right: 3px solid transparent;
`

const Loader = (props) => (
  <LoaderBackground {...props}>
    <LoaderForeground {...props} className={null} />
  </LoaderBackground>
)

export const StaticLoader = styled(Loader).attrs({size: 70})`
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -${props => (props.size || 25)/10/2}rem;
  margin-left: -${props => (props.size || 25)/10/2}rem;
`