let showLogs = true

const _printLog = console.log

function log(message?: any, ...optionalParams: any[]):void {
  if (showLogs) {
    return _printLog(message, ...optionalParams)
  }
}

export function displayLogs(show:boolean) {
  showLogs = show
  return showLogs
}

if (process.env.REACT_APP_DEFAULT_HIDE_LOGS) {
  console.log = log
  displayLogs(false)
}