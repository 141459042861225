import React from 'react'
import styled from 'styled-components/macro'
import { Button, Container, Text } from 'roadie-ui'
import { ReactComponent as LogoText } from 'svg/logo-text.svg'

const LogoTextIcon = styled(LogoText)`
  height: 1.2rem;
  width: 8rem;
    
  .icon-fill {
    fill: currentColor;
  }
`

const PoweredByRoadie = () => (
  <Button.A.Tertiary href="https://roadie.com" target="_blank">
    <Container.Grid 
      $gridTemplateColumns="auto auto"
      $alignItems="center"
      $justifyContent="center"
      $gridGap="0.4rem"
      $color="grey.darker"
    >
      <Text.Overline $fontSize="1rem" $margin="0 0.4rem 0 0">Delivery By</Text.Overline>
      <LogoTextIcon />
    </Container.Grid>
  </Button.A.Tertiary>
)

export default PoweredByRoadie