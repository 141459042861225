import { api } from 'roadie-ui'
import { TrackingApiData } from 'rui/track/types'

export async function fetchTrackingData(code:string) {
  const resp = await api.version(3).get(`/gigs/${code.toLowerCase()}/track`)
  return resp.data as TrackingApiData
}

export async function fetchTrackingDriverAvatar(code:string) {
  const resp = await api.version(3).get(`/gigs/${code.toLowerCase()}/track_driver_avatar`)
  return resp.data as { avatar_url?: string, verified_avatar_url?: string }
}

export async function fetchTrackingDeliveryImages(code:string) {
  const resp = await api.version(3).get(`/gigs/${code.toLowerCase()}/track_delivery_images`)
  return resp.data as { images: { id:number, index:number | null, type: "delivery", url: string}[] }
}