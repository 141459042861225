const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);


export function register() {
  console.log('Registering RoadieWorker')
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      console.log('Not registering RoadieWorker on non same origin')
      return;
    }

    window.addEventListener('load', () => {
      if (isLocalhost) {
        console.log('Not registering RoadieWorker on local host')
      } else {
        const swUrl = `${process.env.PUBLIC_URL}/roadie-worker.js`;
        // Is not localhost. Just register service worker
        navigator.serviceWorker.register(swUrl).then(
          reg => {
            console.log(`RoadieWorker registered ${reg.scope}`)
          },
          err => {
            console.log(`RoadieWorker failed ${err}`)
          }
        )
      }
    });
  } else {
    console.log('Not registering RoadieWorker either not production or not available.')
  }
}

export function unregister() {
  console.log('Unregistering RoadieWorker')
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}